import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const newsCarousels = document.querySelectorAll(
    '.posts-slider-block__carousel'
  );

  if (0 < newsCarousels.length) {
    newsCarousels.forEach(function (newsCarousel) {
      new Swiper(newsCarousel, {
        draggable: true,
        slidesPerView: '1.2',
        spaceBetween: 20,
        pagination: {
          el: newsCarousel
            .closest('.posts-slider-block__carousel')
            .querySelector('.swiper-pagination'),
          clickable: true,
          type: 'fraction',
        },
        navigation: {
          nextEl: newsCarousel
            .closest('.posts-slider-block__carousel')
            .querySelector('.swiper-button-next'),
          prevEl: newsCarousel
            .closest('.posts-slider-block__carousel')
            .querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          600: {
            slidesPerView: '1.2',
          },
          769: {
            slidesPerView: '2.4',
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: '3.4',
            spaceBetween: 60,
          },
        },
      });
    });
  }
});
